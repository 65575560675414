import React from "react";
import { LayoutMain } from "../layouts/layout-main/layout-main";
import { LatestArticles, SidebarMain } from "../components";
import { useSiteMetadata } from "../hooks/use-site-metadata";

export default function IndexPage() {
  const { author } = useSiteMetadata();

  return (
    <LayoutMain isBlogPost={false} title="Home">
      <div className="elr-center-block elr-flex">
        <SidebarMain title={author.name}>
          <p>
            Hi I'm Beth! I'm a full-stack software engineer. I have over 15
            years of experience building web applications using CSS, JavaScript,
            Vue, React and Laravel.
          </p>
        </SidebarMain>
        <main className="content-holder">
          <LatestArticles />
        </main>
      </div>
    </LayoutMain>
  );
}
